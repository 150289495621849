import { Injectable, EventEmitter } from '@angular/core';
import { Observable } from 'rxjs';
import { BaseService } from './base.service';
import { DashBoardFiltro } from '../models/dashboadFiltro';

@Injectable()
export class DashboardEnviosService extends BaseService {

	onAtualizar: EventEmitter<any> = new EventEmitter();
	constructor(){
		super('/api/dashboardenvios/')
	}

	onPesquisar(filtro: DashBoardFiltro) {
        this.onAtualizar.emit(filtro);
    }

	public getResumo(model : DashBoardFiltro): Observable<any> {
		return this._http.post(`${this._config.baseUrl}${this._api}resumo`, model);
	}

	public getStagesGrafico(model : DashBoardFiltro): Observable<any> {
		return this._http.post(`${this._config.baseUrl}${this._api}entregas`, model);
	} 

	public getStagesRastreadasGrafico(model : DashBoardFiltro): Observable<any> {
		return this._http.post(`${this._config.baseUrl}${this._api}entregas/rastreadas`, model);
	} 

	public getStagesErro(model : DashBoardFiltro): Observable<any> {
		return this._http.post(`${this._config.baseUrl}${this._api}erros`, model);
	} 

	public getStagesSeller(): Observable<any> {
		return this._http.get(`${this._config.baseUrl}${this._api}sellerstage`);
	} 

	public getResumoUltimosMeses(model : DashBoardFiltro): Observable<any> {
		return this._http.post(`${this._config.baseUrl}${this._api}resumomeses`, model);
	}
}
